import axios from "axios";

export default {
  name: "ResetPassword",
  components: {},
  data() {
    return {
      email: "",
      forgotPwdBtnText: "Reset Password",
      rules: {
        required: (val) =>
          (val !== null && val.length !== 0) || "This field is required.",
      },
    };
  },
  methods: {
    async resetPassword() {
      try {
        this.forgotPwdBtnText = "Please wait...";
        const validate = await this.$refs.changePasswordForm.validateAsync();

        if (!validate) return;

        this.forgotPasswordButtonLabel = "Please wait...";

        const res = await axios({
          method: "post",
          url: "/auth/forgot-password",
          data: {
            email: this.email,
          },
        });
        if (res.status === 200) {
          this.showNotifyMessage({
            message: "Password reset instruction has been sent to your email.",
            type: "success",
          });
          this.$router.push("/login");
        }
      } catch (error) {
        if (this.objectHasOwnProperty(error, "response")) {
          if (this.objectHasOwnProperty(error.response, "status")) {
            if (error.response.status === 500)
            this.showNotifyMessage({
              message: "Email not found.",
              type: "danger",
            });
          }
        }
      } finally {
        this.forgotPwdBtnText = "Reset Password";
      }
    },
  },
};
